define("superfly/pods/frame/settings/billing/components/products-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UTFq+/ZY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Current stitching plan\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"company\",\"hasStitchingPlan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[23,[\"company\",\"stitchingPlan\",\"name\"]],false],[0,\" plan, \"],[1,[21,\"photoLimit\"],false],[0,\" photo upload limit.\\n\\n\"],[4,\"if\",[[23,[\"isFreeTrial\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Billed \"],[1,[23,[\"company\",\"stitchingPlan\",\"billingCycle\"]],false],[0,\".\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"None.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"mt-5\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Current inventory management plan\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"hasInventoryManagement\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"InSites \"],[1,[21,\"siteLimit\"],false],[0,\" Sites billed \"],[1,[23,[\"company\",\"inventoryManagementPlan\",\"billingCycle\"]],false],[0,\".\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"None.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "superfly/pods/frame/settings/billing/components/products-section/template.hbs"
    }
  });

  _exports.default = _default;
});